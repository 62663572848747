/**
 * Created by stas on 13/05/16.
 */


// $("div.lazy").lazyload({
//     effect : "fadeIn"
// });


$(function() {
    // Load the classic theme
    Galleria.loadTheme('js/galleria.classic.js');

    // Initialize Galleria
    Galleria.run('#galleria');
});

$('#toggle').click(function() {
    $(this).toggleClass('active');
    $('#overlay').toggleClass('open');
});

$('.link').click(function() {
    $('#toggle').toggleClass('active');
    $('#overlay').toggleClass('open');
});

$('#impressum').click(function () {
    $('#myModal').css("display", "block");
});

$('#close').click(function () {
    $('#myModal').css("display", "none");
});
